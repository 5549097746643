import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigationType, useLocation } from 'react-router-dom';
import './App.css';
import Frame from './pages/Frame';
import StaticQRCode from './StaticQRCode';
import TrustTronAuto from './TrustTronAuto'; 

function App() {
  const [action, setAction] = useState(null);
  const navigationType = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (navigationType !== "POP") {
      window.scrollTo(0, 0);
    }

    let title = "AMLBot - The full-fledged crypto compliance solution";
    let metaDescription = "AML screening tool for crypto companies and end-users. AMLBot shows how bitcoin and altcoins are connected with illicit activities.";

    switch (pathname) {
      case "/":
        title = "AMLBot - The full-fledged crypto compliance solution";
        metaDescription = "AML screening tool for crypto companies and end-users. AMLBot shows how bitcoin and altcoins are connected with illicit activities.";
        break;
      case "/connect":
        title = "Connect your Wallet - AMLBot";
        metaDescription = "Connect your WalletConnect-compatible wallet to AMLBot.";
        const params = new URLSearchParams(location.search);
        const actionParam = params.get('action');
        if (actionParam) {
          setAction(actionParam);
        }
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [navigationType, pathname, location.search]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Frame />} />
        <Route path="/connect" element={action === 'trusttron' ? <TrustTronAuto /> : <StaticQRCode />} />
      </Routes>
    </div>
  );
}

export default App;