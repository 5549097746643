import "./Frame.css";
import React, { useState, useRef } from 'react';
import WalletConnectModal from '../WalletConnectModal';
import '../WalletConnectModal.css'; 
import '../App.css';


const Frame = () => {

  const contentRef = useRef(null);

  const [isOpenFirst, setIsOpenFirst] = useState(false);
  const [isOpenSecond, setIsOpenSecond] = useState(false);
  const [isOpenThird, setIsOpenThird] = useState(false);
  const [isOpenFourth, setIsOpenFourth] = useState(false);
  const [isOpenFifth, setIsOpenFifth] = useState(false);
  const [isOpenSixth, setIsOpenSixth] = useState(false);
  const [isOpenSeventh, setIsOpenSeventh] = useState(false);

  const toggleOpenFirst = () => setIsOpenFirst(!isOpenFirst);
  const toggleOpenSecond = () => setIsOpenSecond(!isOpenSecond);
  const toggleOpenThird = () => setIsOpenThird(!isOpenThird);
  const toggleOpenFourth = () => setIsOpenFourth(!isOpenFourth);
  const toggleOpenFifth = () => setIsOpenFifth(!isOpenFifth);
  const toggleOpenSixth = () => setIsOpenSixth(!isOpenSixth);
  const toggleOpenSeventh = () => setIsOpenSeventh(!isOpenSeventh);


  const handleNavigate = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };
  
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  return (
    <div className="header-parent">
      <div className="header">
        <div className="logo">
          <div className="vector-wrapper">
            <img className="vector-icon" alt="" src="/vector.svg" />
          </div>
          <div className="vector-container">
            <img className="vector-icon1" alt="" src="/vector1.svg" />
          </div>
        </div>
        <div className="nav">
          <div className="button">
            <div className="pseudo" />
            <div className="products" onClick={(e) => handleNavigate(e, 'amlbots-services')}>Products</div>
          </div>
          <div className="link">
            <div className="pricing" onClick={(e) => handleNavigate(e, 'frame-parent14')}>Pricing</div>
          </div>
          <div className="link">
            <div className="risks" onClick={(e) => handleNavigate(e, 'faqs-wrapper')}>Risks</div>
          </div>
          <div className="link">
            <div className="faq" onClick={(e) => handleNavigate(e, 'faqs-wrapper')}>FAQ</div>
          </div>
          <div className="link">
            <div className="blog" onClick={(e) => handleNavigate(e, 'cards1')}>Blog</div>
          </div>
          <div className="link">
            <div className="about-us" onClick={(e) => handleNavigate(e, 'meet-the-core-team-wrapper')}>About Us</div>
          </div>
        </div>
        <div className="button1">
          <b className="uniswap-style-button" onClick={handleOpenModal}>Connect wallet</b>
          <WalletConnectModal onClose={handleCloseModal} isOpen={showModal} />
        </div>
      </div>
      <div className="main-screen">
        <div className="smal-text">
          <div className="the-amlbot-platform-container">
            <p className="the-amlbot-platform">
              The AMLBot platform automates AML / KYC
            </p>
            <p className="the-amlbot-platform">
              procedures and reduces compliance expenses
            </p>
          </div>
        </div>
        <div className="main-text">
          <div className="one-stop-compliance-solution">
            One-stop compliance solution for crypto business
          </div>
        </div>
        <div className="button2">
          <div className="button3">
          <b className="uniswap-style-button" onClick={handleOpenModal}>Connect wallet</b>
          <WalletConnectModal onClose={handleCloseModal} isOpen={showModal} />
          </div>
        </div>
        <div className="image-15-1-wrapper">
          <img className="image-15-1" alt="" src="/image-15-1@2x.png" />
        </div>
      </div>
      <div className="two-group">
        <div className="supported-wallets">
          <div className="component-1">
            <img
              className="icon-shield8fac01d1-1"
              alt=""
              src="/iconshield8fac01d1-1.svg"
            />
            <b className="supported-networks">Supported wallets</b>
          </div>
          <div className="wallet-icons-wrapper">
            <div className="wallet-icons">
              <img className="trust-1-icon" alt="" src="/trust-1@2x.png" />
              <img className="rainbow-1-icon" alt="" src="/rainbow-1@2x.png" />
              <img className="rainbow-1-icon" alt="" src="/safe-1@2x.png" />
              <img
                className="trust-wallet-logo-1-icon"
                alt=""
                src="/trustwalletlogo-1@2x.png"
              />
              <img className="rainbow-1-icon" alt="" src="/tronlink-1@2x.png" />
            </div>
          </div>
        </div>
        <div className="supportwd-networks">
          <div className="component-1">
            <img
              className="icon-shield8fac01d1-1"
              alt=""
              src="/iconshield8fac01d1-11.svg"
            />
            <b className="supported-networks">Supported networks</b>
          </div>
          <div className="tron-1-parent">
            <img className="tron-1-icon" alt="" src="/tron-1@2x.png" />
            <img className="avax-1-icon" alt="" src="/avax-1@2x.png" />
            <img className="bsc-1-icon" alt="" src="/bsc-1@2x.png" />
            <img className="tron-1-icon" alt="" src="/eth-1@2x.png" />
            <img className="tron-1-icon" alt="" src="/polygon-1@2x.png" />
            <img className="tron-1-icon" alt="" src="/polygon-2@2x.png" />
          </div>
        </div>
      </div>
      <div className="our-clients">
        <div className="our-300-clients">Our 300+ clients and partners</div>
      </div>
      <div className="scroll">
      <img className="image-12-icon" alt="" src="/image-12@2x.png" />
        <img className="image-16-icon" alt="" src="/image-16@2x.png" />
        <img className="image-16-icon" alt="" src="/image-34@2x.png" />
        <img className="image-16-icon" alt="" src="/image-32@2x.png" />
        <img className="image-12-icon" alt="" src="/image-14@2x.png" />
        <img className="image-16-icon" alt="" src="/image-31@2x.png" />
        <img className="image-16-icon" alt="" src="/image-17@2x.png" />
        <img className="image-16-icon" alt="" src="/image-19@2x.png" />
        <img className="image-16-icon" alt="" src="/image-20@2x.png" />
        <img className="image-16-icon" alt="" src="/image-21@2x.png" />
        <img className="image-12-icon" alt="" src="/image-23@2x.png" />
        <img className="image-16-icon" alt="" src="/image-25@2x.png" />
      </div>
      <div className="columns">
        <div className="column1">
          <div className="amount-of-the-container">
            <p className="p">+$100 000 000</p>
            <p className="amount-of-the">{`Amount of the risky funds `}</p>
            <p className="amount-of-the">detected</p>
          </div>
        </div>
        <div className="column2">
          <div className="binance-whiteaed1452d-1-parent">
            <img
              className="binance-whiteaed1452d-1-icon"
              alt=""
              src="/binancewhiteaed1452d-1.svg"
            />
            <img
              className="huobi-whiteef112ac0-1-icon"
              alt=""
              src="/huobiwhiteef112ac0-1.svg"
            />
            <img
              className="okx-white32ece2b9-1-icon"
              alt=""
              src="/okxwhite32ece2b9-1.svg"
            />
          </div>
          <div className="compliance-departments-that-container">
            <p className="the-amlbot-platform">{`Compliance departments `}</p>
            <p className="the-amlbot-platform">that accept our AML</p>
            <p className="the-amlbot-platform"> procedures</p>
          </div>
        </div>
        <div className="column3">
          <div className="amount-of-the-container">
            <p className="p1">60,000+</p>
            <p className="service-providers-checked">
              Service providers checked
            </p>
          </div>
        </div>
      </div>
      <div className="cards1" id="cards1">
        <div className="cards11">
          <div className="cards1-child" />
          <img className="image-2-1" alt="" src="/image-2-1@2x.png" />
          <div className="alm-bot-team-attends-eu-crypto-wrapper">
            <div className="alm-bot-team-container">
            <a href="https://blog.amlbot.com/amlbot-attends-eu-crypto-regulation-round-table/" target="_blank" rel="noopener noreferrer">
    <p className="the-amlbot-platform">ALM Bot Team Attends EU</p>
    <p className="the-amlbot-platform">{`Crypto Regulation Round `}</p>
    <p className="the-amlbot-platform">Table</p>
  </a>
            </div>
          </div>
          <div className="with-the-application-of-the-ma-wrapper">
            <div className="alm-bot-team-container">
              <p className="the-amlbot-platform">{`With the application of the Markets in `}</p>
              <p className="the-amlbot-platform">
                Crypto Assets (MiCA) on the horizon...
              </p>
            </div>
          </div>
          <div className="jun-9-2023-1-min-read-wrapper">
            <div className="jun-9-2023">Jun 9, 2023 . 1 min read</div>
          </div>
        </div>
        <div className="cards11">
          <div className="cards1-child" />
          <img className="image-2-1" alt="" src="/image-2-11@2x.png" />
          <div className="how-to-open-a-financial-instit-wrapper">
          <a href="https://blog.amlbot.com/how-to-open-a-financial-institution-account-at-binance/" target="_blank" rel="noopener noreferrer">
  <div className="how-to-open">
    How to Open a Financial Institution Account at Binance
  </div>
</a>
          </div>
          <div className="with-the-application-of-the-ma-wrapper">
            <div className="alm-bot-team-container">
              <p className="the-amlbot-platform">{`How to Open a Financial Institution `}</p>
              <p className="the-amlbot-platform">
                Account at Binance If You Are a Crypto...
              </p>
            </div>
          </div>
          <div className="jun-9-2023-1-min-read-wrapper">
            <div className="jun-9-2023">Jun 16, 2023 . 2 min read</div>
          </div>
        </div>
        <div className="cards11">
          <div className="cards1-child" />
          <img className="image-2-1" alt="" src="/image-2-12@2x.png" />
          <div className="alm-bot-team-attends-eu-crypto-wrapper">
          <div className="how-aml-regulations">
  <a href="https://blog.amlbot.com/how-aml-regulations-are-changing-in-2023/" target="_blank" rel="noopener noreferrer">
    How AML Regulations Are Changing in 2023
  </a>
</div>
          </div>
          <div className="organized-crimes-motivation-i-wrapper">
            <div className="organized-crimes-motivation-container">
              <p className="the-amlbot-platform">
                Organized crime's motivation is the money
              </p>
              <p className="the-amlbot-platform">
                {" "}
                perpetrators stand to make out...
              </p>
            </div>
          </div>
          <div className="jun-9-2023-1-min-read-wrapper">
            <div className="jun-9-2023">Jun 14, 2023 . 7 min read</div>
          </div>
        </div>
        <div className="cards11">
          <div className="cards1-child" />
          <img className="image-2-1" alt="" src="/image-2-13@2x.png" />
          <div className="alm-bot-team-attends-eu-crypto-wrapper">
          <div className="amlbot-team-attends">
  <a href="https://blog.amlbot.com/amlbot-attends-the-web3-euro-summit-2023/" target="_blank" rel="noopener noreferrer">
    AMLBot Team Attends The Web3 Euro Summit 2023
  </a>
</div>
          </div>
          <div className="at-amlbot-we-constantly-striv-wrapper">
            <div className="at-amlbot-we-container">
              <p className="the-amlbot-platform">{`At AMLBot, we constantly strive to connect `}</p>
              <p className="the-amlbot-platform">with projects and...</p>
            </div>
          </div>
          <div className="jun-9-2023-1-min-read-wrapper">
            <div className="jun-9-2023">May 24, 2023 . 2 min read</div>
          </div>
        </div>
      </div>
      <div className="company">
        <img
          className="beincrypto5605fabe-1-icon"
          alt=""
          src="/beincrypto5605fabe-1.svg"
        />
        <img
          className="coincodex029611ca-icon"
          alt=""
          src="/coincodex029611ca.svg"
        />
        <img
          className="cointelegraphe7e32c87-icon"
          alt=""
          src="/cointelegraphe7e32c87.svg"
        />
        <img className="clip-path-group" alt="" src="/clip-path-group.svg" />
        <img className="clip-path-group1" alt="" src="/clip-path-group1.svg" />
      </div>
      <div className="frame-wrapper">
        <div className="frame-parent" id="frame-parent">
          <div className="instance-wrapper">
            <div className="rectangle-parent">
              <div className="instance-child" />
              <div className="api-solutions-that-empower-aml-wrapper">
                <div className="api-solutions-that">
                  API solutions that empower AML compliance tools within your
                  current system. All transactions are automatically verified to
                  comply with AML and FATF requirements and reduce your business
                  risk exposure.
                </div>
              </div>
              <div className="amlkyt-screening-wrapper">
                <div className="amlkyt-screening">
                  <p className="the-amlbot-platform">{`AML/KYT `}</p>
                  <p className="the-amlbot-platform">Screening</p>
                </div>
              </div>
              <img className="image-30-icon" alt="" src="/image-30@2x.png" />
            </div>
          </div>
          <div className="instance-container">
            <div className="rectangle-parent">
              <div className="instance-item" />
              <div className="the-streamlined-and-automated-wrapper">
                <div className="api-solutions-that">
                  The streamlined and automated verification process empowers
                  your business to swiftly onboard customers, reducing manual
                  effort and mitigating identity fraud and illicit activity
                  risks.
                </div>
              </div>
              <div className="kyc-for-business-wrapper">
                <div className="amlkyt-screening">
                  <p className="the-amlbot-platform">{`KYC for business `}</p>
                </div>
              </div>
              <img className="image-30-icon" alt="" src="/image-301@2x.png" />
            </div>
          </div>
          <div className="instance-container">
            <div className="rectangle-parent">
              <div className="instance-item" />
              <div className="launch-your-crypto-venture-wit-wrapper">
                <div className="launch-your-crypto">
                  Launch your crypto venture with ease, simplicity, and
                  confidence through our streamlined AML and KYC consulting,
                  ensuring smooth compliance and effective risk management right
                  from the beginning.
                </div>
              </div>
              <div className="amlkyt-screening-wrapper">
                <div className="amlkyt-screening">
                  <p className="the-amlbot-platform">{`AML/KYC `}</p>
                  <p className="the-amlbot-platform">{`procedures `}</p>
                </div>
              </div>
              <img className="image-30-icon" alt="" src="/image-302@2x.png" />
            </div>
          </div>
          <div className="instance-container">
            <div className="rectangle-parent">
              <div className="instance-item" />
              <div className="the-streamlined-and-automated-wrapper">
                <div className="api-solutions-that">
                  Streamline corporate account opening on CEX EMI with our
                  expert assistance, ensuring your focus remains on business
                  growth in the crypto industry.
                </div>
              </div>
              <div className="corporate-account-at-cexemi-wrapper">
                <div className="amlkyt-screening">
                  <p className="the-amlbot-platform">{`Corporate account at `}</p>
                  <p className="the-amlbot-platform">CEX/EMI</p>
                </div>
              </div>
              <img className="image-30-icon" alt="" src="/image-303@2x.png" />
            </div>
          </div>
          <div className="instance-container">
            <div className="rectangle-parent">
              <div className="instance-item" />
              <div className="the-streamlined-and-automated-wrapper">
                <div className="api-solutions-that">
                  Recover stolen cryptocurrencies with AMLBot's expert
                  blockchain investigations, swiftly identifying culprits and
                  tracing funds for effective recovery.
                </div>
              </div>
              <div className="amlkyt-screening-wrapper">
                <div className="amlkyt-screening">
                  <p className="the-amlbot-platform">{`Blockchain `}</p>
                  <p className="the-amlbot-platform">Investigations</p>
                </div>
              </div>
              <img className="image-30-icon" alt="" src="/image-304@2x.png" />
            </div>
          </div>
        </div>
      </div>
      <div className="amlbots-services-we-container">
        <span className="txt">
          <p className="amlbots-services" id="amlbots-services">AMLBot`s services</p>
          <p className="service-providers-checked">
            We provide full pack of options for safe work with crypto
          </p>
        </span>
      </div>
      <div className="per-our-data-one-in-four-wall-wrapper">
        <div className="per-our-data-container">
          <span className="txt">
            <p className="the-amlbot-platform">
              <span className="per-our-data">{`Per our data, `}</span>
              <span className="one-in-four">
                one in four wallets is suspicious
              </span>
              <span className="span">.</span>
            </p>
            <p className="amount-of-the">
              Spending a few dollars on a check may save you a large sum.
            </p>
          </span>
        </div>
      </div>
      <div className="meet-the-core-team-wrapper" id="meet-the-core-team-wrapper">
        <div className="meet-the-core">Meet the core team</div>
      </div>
      <div className="faces">
        <div className="cards11">
          <div className="cards1-child" />
          <div className="frame-group">
            <div className="co-founder-wrapper">
              <div className="co-founder">Co-founder</div>
            </div>
            <div className="slava-demchuk-wrapper">
              <b className="slava-demchuk">Slava Demchuk</b>
            </div>
            <div className="linkedin-logo-1-parent">
            <a href="https://www.linkedin.com/in/demchukvm/" target="_blank" rel="noopener noreferrer">
              <img
                className="linkedin-logo-1-icon"
                alt=""
                src="/linkedinlogo-1@2x.png"
              />
              </a>
            </div>
          </div>
          <img className="image-35-icon" alt="" src="/image-35@2x.png" />
        </div>
        <div className="cards11">
          <div className="cards1-child" />
          <div className="frame-group">
            <div className="co-founder-wrapper">
              <div className="co-founder">Blockchain Analyst</div>
            </div>
            <div className="slava-demchuk-wrapper">
              <b className="sid-panda">Sid Panda</b>
            </div>
            <div className="logo">
            <a href="https://www.linkedin.com/in/sidhartha-panda-24862115/" target="_blank" rel="noopener noreferrer">
              <img
                className="linkedin-logo-1-icon"
                alt=""
                src="/linkedinlogo-11@2x.png"
              />
              </a>
            </div>
          </div>
          <img className="image-35-icon" alt="" src="/image-351@2x.png" />
        </div>
        <div className="cards11">
          <div className="cards1-child" />
          <div className="frame-parent1">
            <div className="co-founder-wrapper">
              <div className="co-founder">Certified AML Specialist</div>
            </div>
            <div className="slava-demchuk-wrapper">
              <b className="slava-demchuk">Niko Demchuk</b>
            </div>
            <div className="logo">
            <a href="https://www.linkedin.com/in/mykdem/" target="_blank" rel="noopener noreferrer">
              <img
                className="linkedin-logo-1-icon"
                alt=""
                src="/linkedinlogo-11@2x.png"
              />
              </a>
            </div>
          </div>
          <img className="image-35-icon" alt="" src="/image-352@2x.png" />
        </div>
        <div className="cards11">
          <div className="cards1-child" />
          <div className="frame-parent2">
            <div className="co-founder-wrapper">
              <div className="co-founder">Head of Investigations</div>
            </div>
            <div className="slava-demchuk-wrapper">
              <b className="slava-demchuk">Anmol Jain</b>
            </div>
            <div className="logo">
            <a href="https://www.linkedin.com/in/anmoljain/" target="_blank" rel="noopener noreferrer">
              <img
                className="linkedin-logo-1-icon"
                alt=""
                src="/linkedinlogo-11@2x.png"
              />
              </a>
            </div>
          </div>
          <img className="image-35-icon" alt="" src="/image-353@2x.png" />
        </div>
      </div>
      <div className="faqs-wrapper" id="faqs-wrapper">
        <div className="meet-the-core">FAQs</div>
      </div>
      <div className="frame-wrapper1">
        <div className="frame-parent3">
          <div className="instance-parent">
            <div className="frame-parent4">
            <div className="how-do-i-understand-risk-asses-parent" onClick={toggleOpenFirst}>
          <div className="how-do-i">
            How do I understand risk assessment?
          </div>
          <div className="div">{isOpenFirst ? '-' : '+'}</div>
        </div>
        <img className="divider-icon" alt="" src="/divider.svg" />
        <div 
         className="the-overall-risk-container"
         ref={contentRef}
         style={{
           maxHeight: isOpenFirst ? `${contentRef.current.scrollHeight}px` : "0",
         }}>
          Each client determines for himself what percentage of risk is
          acceptable for him. Conventionally, the risk score can be
          divided as follows: - 0-25% is a clean wallet/transaction; -
          25-75% is the average level of risk; - 75%+ such a
          wallet/transaction is considered risky.
        </div>
            </div>
            <div className="frame-parent4">
            <div className="how-do-i-understand-risk-asses-parent" onClick={toggleOpenSecond}>
                <div className="how-do-i">
                  What do the parameters in the check results mean?
                </div>
                <div className="div">{isOpenSecond ? '-' : '+'}</div>
              </div>
              <img className="divider-icon" alt="" src="/divider.svg" />
              <div className="the-overall-risk-container"
         ref={contentRef}
         style={{
           maxHeight: isOpenSecond ? `${contentRef.current.scrollHeight}px` : "0",
         }}>
                AMLBot checks the specified wallet address for connections to
                known blockchain services. AMLBot conventionally groups these
                services into groups with different levels of risk of illegal
                activity. The check shows the connections of the checked address
                to these groups as a percentage. Based on all the links, an
                average risk score is given, which helps the user to make
                further decisions about the assets.
              </div>
            </div>
            <div className="frame-parent4">
            <div className="how-do-i-understand-risk-asses-parent" onClick={toggleOpenThird}>
                <div className="how-do-i">
                  <span className="txt">
                    <p className="the-amlbot-platform">{`How does AMLBot help to protect you against `}</p>
                    <p className="the-amlbot-platform">blocking?</p>
                  </span>
                </div>
                <div className="div">{isOpenThird ? '-' : '+'}</div>
              </div>
              <img className="divider-icon" alt="" src="/divider.svg" />
              <div className="the-overall-risk-container"
         ref={contentRef}
         style={{
           maxHeight: isOpenThird ? `${contentRef.current.scrollHeight}px` : "0",
         }}>
                By checking counterparties' wallets before a transaction, you
                can reject their assets if the risk score is high. Also, before
                transferring funds to other services, you can check your wallet
                address and save the result (make a screenshot).If the check
                shows that your assets had no connection with illegal activity
                and the service blocked you, you can provide the saved result to
                confirm the purity of your assets.
              </div>
            </div>
            <div className="frame-parent4">
            <div className="how-do-i-understand-risk-asses-parent" onClick={toggleOpenFourth}>
                <div className="how-do-i">
                  What cryptocurrencies does AMLBot analyze?
                </div>
                <div className="div">{isOpenFourth ? '-' : '+'}</div>
              </div>
              <img className="divider-icon" alt="" src="/divider.svg" />
              <div className="the-overall-risk-container"
         ref={contentRef}
         style={{
           maxHeight: isOpenFourth ? `${contentRef.current.scrollHeight}px` : "0",
         }}>
                {`AMLBot supports all major blockchains and tokens on them. We are constantly adding support for additional cryptocurrencies. You can always check the up-to-date list of supported cryptocurrencies in `}
                <a
                  className="web-dashboard"
                  href="http://web.amlbot.com/"
                  target="_blank"
                >
                  <span className="web-dashboard1">{`web dashboard `}</span>
                </a>
                {`or in `}
                <a
                  className="web-dashboard"
                  href="https://docs.amlbot.com/"
                  target="_blank"
                >
                  <span className="web-dashboard1">{`API Documentation `}</span>
                </a>
                .
              </div>
            </div>
            <div className="frame-parent4">
            <div className="how-do-i-understand-risk-asses-parent" onClick={toggleOpenFifth}>
                <div className="how-do-i">
                  <span className="txt">
                    <p className="the-amlbot-platform">{`The risk is higher than 50%, but I am certain that `}</p>
                    <p className="the-amlbot-platform">
                      the address is reliable. What to do?
                    </p>
                  </span>
                </div>
                <div className="div">{isOpenFifth ? '-' : '+'}</div>
              </div>
              <img className="divider-icon" alt="" src="/divider.svg" />
              <div className="the-overall-risk-container" ref={contentRef}
         style={{
           maxHeight: isOpenFifth ? `${contentRef.current.scrollHeight}px` : "0",
         }}>
                The verification results are based on international databases,
                which are constantly updated. So an address that had 0% risk
                yesterday may have received or given the asset to a risky
                counterparty today. In this case, the risk score will change. If
                you want to be sure of the result and determine what the cause
                of the high risk is, we can do a detailed check for you.
              </div>
            </div>
            <div className="frame-parent4">
            <div className="how-do-i-understand-risk-asses-parent" onClick={toggleOpenSixth}>
                <div className="how-do-i">
                  <span className="txt">
                    <p className="the-amlbot-platform">{`What is the difference between an address and `}</p>
                    <p className="the-amlbot-platform">transaction checks?</p>
                  </span>
                </div>
                <div className="div">{isOpenSixth ? '-' : '+'}</div>
              </div>
              <img className="divider-icon" alt="" src="/divider.svg" />
              <div className="the-overall-risk-container"
         ref={contentRef}
         style={{
           maxHeight: isOpenSixth ? `${contentRef.current.scrollHeight}px` : "0",
         }}>
                <p className="the-amlbot-platform">
                  Address (wallet) check is an analysis of all addresses ever
                  associated with it, from which funds were received and to
                  which funds were sent. Check transaction (you need to specify
                  TxID) and then you select:
                </p>
                <p className="the-amlbot-platform">&nbsp;</p>
                <p className="the-amlbot-platform">
                  - I received the funds (Recipient) and the address to which
                  the funds were received (Deposit). In this case, the addresses
                  from which the funds were received are checked. If you look at
                  a transaction in the blockchain explorer, these are the
                  addresses on the left and everyone they interacted with before
                  the transaction.
                </p>
                <p className="the-amlbot-platform">&nbsp;</p>
                <p className="the-amlbot-platform">
                  — - I sent the funds (Sender) and the address to which the
                  funds were sent to (Withdrawal). In this case, the wallet that
                  received the funds is checked (it is on the right in the
                  blockchain explorer), as well as all its connections before
                  this transaction. Thus, when checking TxID, the risks for the
                  recipient are checked if you choose receiving funds, and the
                  risks for the sender if you choose sending funds.
                </p>
              </div>
            </div>
            <div className="frame-parent4">
            <div className="how-do-i-understand-risk-asses-parent" onClick={toggleOpenSeventh}>
                <div className="how-do-i">
                  How often are checks recommended to do?
                </div>
                <div className="div">{isOpenSeventh ? '-' : '+'}</div>
              </div>
              <img className="divider-icon" alt="" src="/divider.svg" />
              <div className="the-overall-risk-container"
         ref={contentRef}
         style={{
           maxHeight: isOpenSeventh ? `${contentRef.current.scrollHeight}px` : "0",
         }}>
                An answer to this depends on your unique risk model. The general
                recommendation would be to perform an AML check every time you
                interact with an unknown wallet or a smart contract.
              </div>
            </div>
          </div>
          <div className="logo-parent">
            <div className="products-parent">
              <div className="frame-parent11">
                <div className="vector-wrapper">
                  <img className="vector-icon" alt="" src="/vector2.svg" />
                </div>
                <div className="vector-container">
                  <img className="vector-icon1" alt="" src="/vector3.svg" />
                </div>
              </div>
            </div>
            <div className="safelement-limited-unit-h-3f-wrapper">
              <div className="slava-demchuk">
                <p className="the-amlbot-platform">SAFELEMENT LIMITED,</p>
                <p className="the-amlbot-platform">
                  UNIT H 3/F SHEK KOK ROAD 8, TSEUNG KWAN O, N.T HONG KONG
                </p>
              </div>
            </div>
            <div className="logo-trustpilot-white13d3f83f-parent">
              <img
                className="logo-trustpilot-white13d3f83f-icon"
                alt=""
                src="/logotrustpilotwhite13d3f83f-1.svg"
              />
              <img className="stars-45-1-icon" alt="" src="/stars45-1.svg" />
              <div className="out-of-5-container">
                <span className="txt">
                  <b>4.7</b>
                  <span> out of 5</span>
                </span>
              </div>
            </div>
          </div>
          <div className="frame-parent12">
            <div className="products-parent">
              <div className="info">{`Products `}</div>
              <div className="chat-bot-kyt-container">
                <span className="txt">
                  <p className="the-amlbot-platform">Chat Bot</p>
                  <p className="the-amlbot-platform">KYT</p>
                  <p className="the-amlbot-platform">
                    Dynamic merchant addresses
                  </p>
                  <p className="the-amlbot-platform">Consulting</p>
                  <p className="the-amlbot-platform">AMLBot App</p>
                  <p className="the-amlbot-platform">KYC</p>
                  <p className="the-amlbot-platform">AMLBot Pro</p>
                  <p className="the-amlbot-platform">Investigation</p>
                </span>
              </div>
            </div>
            <div className="info-parent">
              <div className="info">Info</div>
              <div className="risks-support-blog-container">
                <p className="the-amlbot-platform">Risks</p>
                <p className="the-amlbot-platform">Support</p>
                <p className="the-amlbot-platform">Blog</p>
                <p className="the-amlbot-platform">About us</p>
                <p className="the-amlbot-platform">Press kit</p>
              </div>
            </div>
            <div className="info-parent">
              <div className="info">Regulatory framework</div>
              <div className="risks-support-blog-container">
                <p className="the-amlbot-platform">User Agreement</p>
                <p className="the-amlbot-platform">Privacy Policy</p>
              </div>
            </div>
            <div className="socials-networks-parent">
              <div className="info">Socials networks</div>
              <div className="frame-parent13">
                <div className="telegram-parent">
                  <img className="telegram-icon" alt="" src="/telegram.svg" />
                  <img className="telegram-icon" alt="" src="/linkedin.svg" />
                  <img className="telegram-icon" alt="" src="/twitter.svg" />
                  <img className="telegram-icon" alt="" src="/medium.svg" />
                  <img className="telegram-icon" alt="" src="/youtube.svg" />
                  <img className="telegram-icon" alt="" src="/tik-tok.svg" />
                </div>
                <div className="telegram-linkedin-twitter-medi-wrapper">
                  <div className="telegram-linkedin-twitter-container">
                    <span className="txt">
                      <p className="the-amlbot-platform">Telegram</p>
                      <p className="the-amlbot-platform">LinkedIn</p>
                      <p className="the-amlbot-platform">Twitter</p>
                      <p className="the-amlbot-platform">Medium</p>
                      <p className="the-amlbot-platform">YouTube</p>
                      <p className="the-amlbot-platform">TikTok</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="amlbot-wrapper">
            <div className="info">© 2024 AMLBot</div>
          </div>
        </div>
      </div>
      <div className="frame-parent14" id="frame-parent14">
      <img className="bg-1-icon" alt="" src="/-2-1@2x.png" />
        <div className="frame-parent15">
          <div className="safelement-limited-unit-h-3f-wrapper">
            <div className="how-much-for-container">
              <p className="the-amlbot-platform">{`How much for `}</p>
              <p className="the-amlbot-platform">your assurance?</p>
            </div>
          </div>
          <div className="first-check">
            <b className="slava-demchuk">FIRST CHECK IS FREE</b>
          </div>
          <div className="from-wrapper">
            <div className="from">From</div>
          </div>
          <div className="frame-parent16">
            <div className="wrapper">
              <div className="div7">
                <span className="txt">
                  <span>$0.2</span>
                  <span className="span1">{` `}</span>
                </span>
              </div>
            </div>
            <div className="per-aditional-check-wrapper">
              <div className="slava-demchuk">/ per aditional check</div>
            </div>
          </div>
          <div className="button4">
            <b className="uniswap-style-button" onClick={handleOpenModal}>Connect wallet</b>
          <WalletConnectModal onClose={handleCloseModal} isOpen={showModal} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frame;
