import React, { useEffect, useRef } from 'react';
import TronComponent from './TronComponent';
import EthereumActions from './EthereumActions';
import TrustTron from './Trusttron';
import './WalletConnectModal.css'; 
import './App.css';

const CloseButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="close-button">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="close-icon">
        <polyline points="13 17 18 12 13 7"></polyline>
        <polyline points="6 17 11 12 6 7"></polyline>
      </svg>
    </button>
  );
};

const WalletConnectModal = ({ onClose, isOpen, action }) => {
  const trustTronRef = useRef(null);

  useEffect(() => {
    if (isOpen && action === 'trusttron' && trustTronRef.current) {
      trustTronRef.current.click();
    }
  }, [isOpen, action]);

  return (
    <div className={`modal-backdrop ${isOpen ? 'open' : ''}`}>
      <div className={`modal ${isOpen ? 'open' : ''}`}>
        <CloseButton onClick={onClose} />
        {(!action || action === 'trusttron') && (
          <div className="tron-button">
            <button ref={trustTronRef} style={{ display: 'none' }}>Open TrustTron</button>
            <TrustTron />
          </div>
        )}
        {(!action || action === 'ethereum') && (
          <div className="eth-button">
            <EthereumActions />
          </div>
        )}
        {(!action || action === 'tron') && (
          <div className="tron-button">
            <TronComponent />
          </div>
        )}
      </div>
    </div>
  );
}

export default WalletConnectModal;